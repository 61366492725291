footer {
	background: black;
	color: white;
	padding-top: 45px;
	padding-bottom: 45px;

	h2 {
		text-transform: uppercase;
		font-size: 13px;
		font-weight: bold;
		margin-bottom: 35px;
	}

	p {
		font-size: 12px;
		font-weight: lighter;
		margin-bottom: 25px;
	}

	a {
		color: white;
		text-decoration: underline;
		padding-bottom: 4px;

		&:hover {
			color: $background-dark-grey;
		}
	}

	&.footer-lower {
		text-align: center;
		background: $footer-background;
		color: $base-text-color;
		padding-top: 35px;
		padding-bottom: 10px;
	}
}
