section.price-list {
  p.price-list-sort {
    padding-top: 30px;
    margin-bottom: -30px;
  }
  th.sorting_asc, th.sorting_desc {
    background: darken($background-sub-nav, 20%);
  }
  table {
    border-collapse: separate;
    border-spacing: 0 1em;
  }
  th, td {
    background-color: $background-sub-nav;
    text-align: center;
    border: none !important;
  }

  tbody > tr.apartment:hover, th:hover {
    cursor: pointer;
  }

  tr.apartment > td, tr > th {
    padding: 15px !important;
  }

  tr:not(.apartment) td {
    background: $background-white;
  }

  a[href=''] {
    display: none;
  }
  .btn {
    margin-top: 20px;
  }
}
