.carousel {
	h1 {
		text-transform: uppercase;

		@include min-screen($screen-sm-min) {
			font-size: 235px;
		}
	}
	.carousel-inner > .item {
		transition: transform 1.2s $ease-standard;
	}

	.carousel-control {
		display: none;
		align-items: center;
		justify-content: center;
		opacity: 0.75;
		background: transparent;
		text-decoration: none;
		width: 3%;
		@include min-screen($screen-sm-min) {
			display: flex;
		}

		&:hover {
			opacity: 1;
		}

		.icon {
			text-shadow: none;
			font-size: 25px;
		}

		.right, .left {
			background: transparent;
		}
	}

	&.carousel--small {
		.carousel-control {
			.icon {
				font-size: 60px;
			}
		}
	}

	.carousel-indicators {
		bottom: 5px;
		margin-bottom: 0;

		@include min-screen($screen-sm-min) {
			bottom: 5px;
		}

		li {
			background: #696663;
			border: 0;
			height: 10px;
			width: 10px;
			margin: 2px;

			@include min-screen($screen-sm-min) {
				height: 14px;
				width: 14px;
				margin: 5px;
			}

			&.active {
				background: #81a0a7;
				border: 0;
			}
		}
	}
}

.carousel-caption {
	left: 5%;
	right: 5%;

	@include min-screen($screen-md-min) {
		left: 20%;
		right: 20%;
	}
}

.carousel-image {
	width: 100%;
	height: 0%;
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
}

.carousel-image-hero {
	padding-bottom: 56%;

	@include min-screen($screen-md-min) {
		padding-bottom: 45%;
	}
}
