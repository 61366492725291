// override bootstrap forms
.form-control {
	border-radius: 0;
	border: none;
	box-shadow: none;
}

.input-group-btn {
	& > .btn {
		border-radius: 0;

		.icon {
			font-size: 14px;
		}
	}
}

.form-group.required label.animate {
	animation-name: flash;
	animation-duration: 0.5s;
	color: red;
}

@keyframes flash {
	0% {
		transform: scale(1);
		color: black;
	}

	20% {
		transform: scale(0.9);
		color: red;
	}

	40% {
		transform: scale(1.0);
		color: black;
	}

	60% {
		transform: scale(1.1);
		color: red;
	}

	80% {
		transform: scale(1.1);
		color: black;
	}

	100% {
		transform: scale(1.0);
		color: red;
	}
}

// override c5 forms
.content-section form {
	margin: 0;

	.col-xs-12 {
		&:first-of-type {
			padding: 0;
		}
	}

	.form-group {
		width: 100%;
		float: left;
		padding: 0 15px;
		&--full {
			width: 100% !important;
		};

		@include min-screen($screen-sm-min) {
			width: 50%;
		}

		&.Zip, &.City {
			width: 100%;

			@include min-screen($screen-sm-min) {
				width: 25%;
			}
		}
	}

	legend {
		display: none;
	}

	.form-group.Interests {
		width: 100%;

		> label {
			display: none;
		}

		.checkbox {
			width: 100%;
			float: left;
			margin-bottom: 0;

			@include min-screen($screen-sm-min) {
				width: 50%;
			}

			&:nth-child(odd) {
				padding-left: 0px;

				@include min-screen($screen-sm-min) {
					padding-left: 10px;
				}
			}
		}
	}

	.form-group.Project {
		display: none;
	}

	fieldset > h3 {
		padding: 0 15px;
		font-size: 14px;
		text-transform: none;
		text-align: center;

		@include min-screen($screen-sm-min) {
			margin-top: 60px;
			margin-bottom: 50px;
			font-size: 40px;
		}
	}

	.radio + .radio, .checkbox + .checkbox {
		margin-top: 10px;
	}

	.custom-checkbox-radio {
		margin-top: 10px;

		label {
			padding-left: 0;
			line-height: 34px;

			&:after {
				content: '';
				display: table;
				clear: both;
			}

			.cr {
				position: relative;
				display: inline-block;
				background-color: white;
				width: 30px;
				height: 30px;
				float: left;
				top: 2px;
				margin-right: 10px;

				.cr-icon {
					position: absolute;
					top: 4px;
					left: 4px;
					font-size: 23px;
				}
			}

			input[type="checkbox"] {
				display: none;

				&.ccm-input-checkbox--static {
					display: inline-block;
					position: absolute;
					opacity: 0;
				}

				& + .cr > .cr-icon {
					opacity: 0;
				}

				&:checked + .cr > .cr-icon {
					opacity: 1;
				}

				&:disabled + .cr {
					opacity: .5;
				}
			}
		}
	}

	button.btn.btn-primary {
		display: block;
		margin: 15px auto 0;
		width: 100%;

		@include min-screen($screen-sm-min) {
			width: 40%;
		}

		@include min-screen($screen-md-min) {
			width: 30%;
		}

		@include min-screen($screen-lg-min) {
			width: 25%;
		}
	}

	.form-thank-you-message {
		display: none;
	}

}
div.ccm-custom-style-container.hasForm{
	padding-top: 0px;
}
section.content-section.hasForm{
	padding-top: 0px;
}
