.spinner {
	width: 16px;
	height: 13px;
	transform-origin: 50% 50%;
	animation: spin 1s infinite linear;
}

@keyframes spin {
	from { transform: rotate(0deg); transform-origin: 50% 50%; }
	to { transform: rotate(360deg); transform-origin: 50% 50%; }
}
