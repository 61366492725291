.top {
	position: relative;
	height: 60px;
	//div {
	//	display: inline-block;
	//}
}

.top__left {
	display: block;
	position: relative;
	top: 50%;
	transform: translateY(-50%);

	@include min-screen($screen-sm-min) {
		display: none;
	}

	.btn-icon {
		border: none;
		width: auto;
		padding: 0;
		text-align: center;
	}

	.js-toggle {
		font-family: $base-headline-family;
		font-size: 13px;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		font-weight: 600;
		padding: 20px 0;
	}
}

.top__center {
	text-align: center;
	position: absolute;
	top: 60%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
}

.logotype {
	display: inline-block;
}
