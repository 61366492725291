button,
.btn {
	background: transparent;
	border-radius: 0;
	border: solid 1px #000;
	line-height: 1;
	padding: 12px 60px;
	font-size: 14px;
	width: 100%;

	@include min-screen($screen-sm-min) {
		font-size: 15px;
		border: solid 2px #000;
		width: auto;
	}

	&:active,
	&.active {
		box-shadow: none;
	}

	&:focus,
	&:active:focus{
		outline: none;
	}
}

.btn.btn-primary,
.btn.btn-default {
	color: black;

	&:hover {
		background: transparent;
	}

	&:active,
	&.active,
	&:hover,
	&:focus,
	&:active:focus,
	&:active.focus,
	&:active:hover {
		border-color: black;
		background: transparent;
		outline: 0;
		color: black;
		box-shadow: none;
	}
}
