@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap-sprockets";
@import "../../../node_modules/sass-mediaqueries/media-queries";
@import "../../../node_modules/WMYL-cookie-consent/dist/css/wmyl-cc-style"; // include cookieBanner

@import "core/core";
@import "vendor/vendor";
@import "layout/layout";
@import "elements/elements";
@import "components/components";
@import "pages/pages";

body {
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-line-height;
	font-weight: 400;
	color: $base-text-color;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
