.alert {
	border: none;
	border-radius: 0;
	text-align: center;
	margin-bottom: 0;
	font-weight: 300;

	a {
		color: #FFF;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	.close {
		width: auto;
		opacity: 0.76;

		&:hover,
		&:focus {
			opacity: 1;
			color: white;
		}
	}
}

.alert-info {
	background-color: $cookie-background;
	color: #FFF;
}
