.list-icons {
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: left;

	.list-icons__item {
		display: inline-block;
		vertical-align: top;
		margin-right: 100px;
		margin-top: 10px;
		margin-bottom: 10px;

		@include min-screen($screen-md-min) {
			margin-right: 50px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		a {
			display: block;
			color: $base-text-color;
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: none;
			}
		}

		.icon {
			font-size: 40px;

			@include min-screen($screen-md-min) {
				font-size: 50px;
			}
		}
	}
}

.list-icons--center {
	text-align: center;

	.list-icons__item {
		margin-left: 15px;
		margin-right: 15px;

		@include min-screen($screen-md-min) {
			margin-left: 25px;
			margin-right: 25px;
		}
	}
}
