.ccm-page {
	transition: padding 0.16s $delay-none $ease-in-out;
	// padding-top: $main-nav-height-desktop;

	@include min-screen($screen-sm-min) {
		// padding-top: $main-nav-height-desktop;
	}
	&.js-menu-is-active {
		padding-top: $main-nav-height-mobile + $sub-nav-height-mobile;

		@include min-screen($screen-sm-min) {
			padding-top: $main-nav-height-desktop + $sub-nav-height-desktop;
		}
	}
}
