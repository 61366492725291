.ccm-block-topic-list-flat-filter {
	.breadcrumb {
		text-align: center;
		padding: 0;
		margin: 0;

		> li {
			width: 165px;
			height: 70px;
			border-left: solid 1px $border-light-grey;
			padding-top: 24px;
			&:last-child {
				border-right: solid 1px $border-light-grey;
			}

			.active {
				color: $link-active-color;
			}
		}
		> li + li:before {
			content: "";
		}
	}

}
