.project-list {
	overflow: hidden;

	.project-list--item-wrapper {
		float: left;

		width: 50%;
		height: 0;
		padding-bottom: 0.5*180*100%/355; // ratio taken from image ratio in design

		position: relative;



		border-top: 1px solid #fff;
		border-bottom: 1px solid #fff;

		&:nth-child(2),&:nth-child(3){
			border-top: 2px solid #fff;
		}

		&:nth-last-child(1){
			border-bottom: 2px solid #fff;
		}

		&:nth-child(even){
			border-right: 1px solid #fff;
		}
		&:nth-child(odd){
			border-left: 1px solid #fff;
		}
		// first item should be an hero image
		&:first-child {
			width: 100%;
			padding-bottom: 310*100%/715;
			border:none;
		}
		@include max-screen($screen-md-min) {
			width: 100%;
			padding-bottom: 180*100%/355; // ratio taken from image ratio in design


			border-top: 0;
			border-bottom: 2px solid #fff;

			&:nth-child(2),&:nth-child(3){
			border-top: 0;
			}

			&:nth-last-child(1){
				border-bottom: 2px solid #fff;
			}

			&:nth-child(even){
				border-right: 0;
			}
			&:nth-child(odd){
				border-left: 0;
			}

			&:first-child {
				padding-bottom: 180*100%/355; // ratio taken from image ratio in design
				border-bottom: 2px solid #fff;
			}
		}

		.list-image {
			position: absolute;
			width: 100%;
			height: 100%;
			transition: opacity 0.25s ease-in-out;

			&:hover {
				opacity: 0;
			}

			&.hover {
				opacity: 0;

				&:hover {
					opacity: 1;
				}
			}

			a {
				display: block;
				overflow: hidden;
				width: 100%;
				height: 100%;

				picture {
					width: 100%;
					height: 100%;

					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}


div.fallback-block{
	width: 100%;
	height: 850px;

	h1{
		text-align: center;
		color: #fff;
		left: auto;
		right: auto;
		left: 0;
		right: 0;
		z-index: 1;
		position: absolute;
		top: 428px;
		vertical-align: middle;
		font-size: 55px;
		font-family: "Roboto slab";
		font-weight: 400;

	}
	#fallback-image{
		width: 100%;
		height: 850px;
		z-index: -1;
		position: fixed;
	}
	@media screen and(max-width: 998px){
		height: 400px;
		h1{
			font-size: 48px;
			top: 213px;
		}
	}
	@media screen and(max-width: 900px){
		height: 400px;
		h1{
			font-size: 43px;
			top: 223px;
		}
	}
	@media screen and(max-width: 804px){
		height: 400px;
		h1{
			font-size: 38px;
			top: 225px;
		}
	}
	@media screen and(max-width: 704px){
		height: 400px;
		h1{
			font-size: 32px;
			top: 232px;
		}
	}
	@media screen and(max-width: 605px){
		height: 400px;
		h1{
			font-size: 28px;
			top: 236px;
		}
	}
	@media screen and(max-width: 507px){
		height: 400px;
		h1{
			font-size: 21px;
			top: 246px;
		}
	}
	@media screen and(max-width: 409px){
		height: 400px;
		h1{
			font-size: 19px;
			top: 248px;
		}
	}
}
