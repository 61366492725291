.image-gallery {

	.image-gallery-item-wrapper {
		width: 100%;
		float: left;
		padding: 0;
		margin-bottom: 30px;

		@include min-screen($screen-sm-min) {
			margin-bottom: 60px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&.collapsed {
			width: 100%;
			float: left;
			padding: 0;

			@include min-screen($screen-sm-min) {
				margin-bottom: 0;
				padding: 2.5px;
				width: 50%;
			}

			&.hide-when-collapsed {
				display: none;
			}

			.image-gallery-show-all__button-wrapper {
				padding: 0;

				@include min-screen($screen-sm-min) {
					padding: 20px;
				}
			}
		}
	}

	.gallery-image {
		width: 100%;
		overflow: hidden;

		img {
			width: 100%;
			height: auto;
		}
	}

	.image-gallery-show-all__button-wrapper {
		width: 100%;
		float: left;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		button {
			width: 100%;
			position: relative;

			@include min-screen($screen-sm-min) {
				width: auto;
			}

			.spinner {
				display: none;
				position: absolute;
				left: 10px;
				top: 13px;
			}

			.collapsed & {
				padding-left: 0;
				padding-right: 0;
				width: 100%;

				@include min-screen($screen-sm-min) {
					width: 50%;
				}
			}

			&.read-more__read-less-button {
				display: none;
			}
		}
	}
}

.image-gallery-items {
	display: flex;
	flex-wrap: wrap;

	&:before, &:after {
		display: block;
	}

	> .image-gallery-item-wrapper {
		display: flex;
		flex-direction: column;

		> .image-gallery-item {
			//display: flex;
			//flex-direction: column;
			//flex: 1 1 auto;
		}
	}
}
