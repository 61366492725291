iframe {
	border: none;
	width: 100%;
	height: 100%;
}

.static-iframe {
	height: 825px;

	@include min-screen(630px) {
		height: 534px;
	}
}

.responsive-iframe {
	position: relative;
	height: 0;
	overflow: hidden;
}

/* 16x9 Aspect Ratio */
.responsive-iframe--16x9 {
	padding-bottom: 56.25%;
}

/* 4x3 Aspect Ratio */
.responsive-iframe--4x3 {
	padding-bottom: 75%;
}

/* 4x4 Aspect Ratio */
.responsive-iframe--1x1 {
	padding-bottom: 100%;
}

.responsive-iframe iframe {
	position: absolute;
	top:0;
	left: 0;
}
