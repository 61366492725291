h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
	font-family: $base-headline-family;
	font-weight: bold;
}

h1, .h1 {
	font-size: 20px;
	margin: 0;

	@include min-screen($screen-sm-min) {
		font-size: 63px;
	}
}

h2, .h2 {
	font-size: 14px;

	@include min-screen($screen-sm-min) {
		font-size: 40px;
	}
}

h3, .h3 {
	font-size: 13px;
	letter-spacing: 0.5px;
	text-transform: uppercase;

	@include min-screen($screen-sm-min) {
		font-size: 13px;
	}
}

p {
	font-family: $base-font-family;
	font-size: 12px;
	line-height: 22px;
	margin: 0 0 30px;
	font-weight: 300;

	strong,
	.lead-text {
		font-weight: 700;
	}

	.lead-text {
		font-family: $base-headline-family;
		font-size: 13px;
		line-height: 22px;
		text-transform: uppercase;
		letter-spacing: 0.5px;
	}
}

p span.ingress {
	font-size: 14px;
	line-height: 20px;

	@include min-screen($screen-sm-min) {
		font-size: 16px;
		line-height: 22px;
	}
}

address {
	font-size: 14px;
	line-height: 20px;
}

.multiple-columns {
	column-gap: 40px;
}

.columns-2 {
	column-count: 1;

	@include min-screen($screen-sm-min) {
		column-count: 2;
	}
}

.columns-3 {
	column-count: 1;

	@include min-screen($screen-sm-min) {
		column-count: 3;
	}
}

.columns-4 {
	column-count: 1;

	@include min-screen($screen-sm-min) {
		column-count: 4;
	}
}

label {
	font-weight: 400;
	font-size: 15px;
}

.form-control {
	font-size: 12px;
}

.checkbox label {
	font-size: 12px;
}
