#header {
	position: fixed;
	z-index: 200;
	width: 100%;
	top: 0;
	transition: transform $duration-fast $delay-none $ease-in-out;

	&.js-nav-up {
		transform: translateY(-70px);
	}

	.ccm-toolbar-visible & {
	}
}

@import "header/top";
