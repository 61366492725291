@font-face {
	font-family: 'svehus-icons';
	src:  url('../fonts/svehus-icons.eot?yugsbn');
	src:  url('../fonts/svehus-icons.eot?yugsbn#iefix') format('embedded-opentype'),
	url('../fonts/svehus-icons.ttf?yugsbn') format('truetype'),
	url('../fonts/svehus-icons.woff?yugsbn') format('woff'),
	url('../fonts/svehus-icons.svg?yugsbn#svehus-icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

.icon {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'svehus-icons' !important;
	speak: none;
	font-size: 32px;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-left:before {
	content: "\e903";
}
.icon-right:before {
	content: "\e904";
}
.icon-facebook:before {
	content: "\e900";
}
.icon-link:before {
	content: "\e901";
}
.icon-sms:before {
	content: "\e902";
}

