$page-width: 1290px;
$main-nav-height-mobile: 70px;
$main-nav-height-desktop: 70px;
$sub-nav-height-mobile: 60px;
$sub-nav-height-desktop: 70px;

$border-radius: 4px;
$base-font-size: 16px;

$base-font-family: "Roboto Slab", serif;
$base-headline-family: "Open Sans", Helvetica, Arial, sans-serif;
$base-font-size: 12px;
$base-line-height: 22px;

$base-text-color: #182224;
$link-active-color: #81A0A7;

$background-white: #FFF;
$background-light-grey: #E1E8EA;
$background-dark-grey: #C0CFD3;
$background-sub-nav: #F2F5F6;
$footer-background: #A8A3A5;
$cookie-background: #7F7F7F;

$border-light-grey: #D9E2E5;
$border-dark-grey: #B3C6CA;

/**
 * Transition
 */

// Duration
$duration-quick: 0.1s;
$duration-fast: 0.26s;
$duration-medium: 0.4s;
$duration-normal: 0.6s;
$duration-slow: 1s;

// Delay
$delay-none: 0s;
$delay-fast: 0.26s;
$delay-medium: 0.4s;

// Easing
$ease-standard: cubic-bezier(0.17, 0.04, 0.03, 0.94);
$ease-in-out: cubic-bezier(0.455, 0.03, 0.515, 0.955);
