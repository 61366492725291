a {
	color: $base-text-color;

	&:hover {
		cursor: pointer;
		color: $base-text-color;
	}
}

.content-section {
	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}
