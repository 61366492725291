.toggle-next-section {

	button {
		&.disable-next-section {
			display: none;
		}
	}

	&+.content-section {
		max-height: 0;
		padding: 0;

		transition: all 0.5s;
		transition-property: padding, max-height, margin;

		@media (max-width: 992px){
			transition: none;
			transition-property: none;
		}

		overflow: hidden;
	}

	&.toggled {

		button {
			&.enable-next-section {
				display: none;
			}

			&.disable-next-section {
				display: inline;
			}
		}

		&+.content-section {


			max-height: 4000px;
			padding: 65px 0;
			padding-top: 0;
			@media (max-width: 768px){
				padding-top: 0;
			}
		}
	}


}
.toggled{
	padding: 0;
}
