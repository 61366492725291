@mixin placeholder {
	&::-webkit-input-placeholder {@content}
	&:-moz-placeholder           {@content}
	&::-moz-placeholder          {@content}
	&:-ms-input-placeholder      {@content}
}

.display-block {
	display: block;
}

.display-inline-block {
	display: inline-block;
}
