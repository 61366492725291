.read-more__content {
	overflow: hidden;
	max-height: 2000px;
	transition: max-height 1.8s $delay-none $ease-standard;
}

.read-more__content {
	h1, .h1,
	h2, .h2,
	h3, .h3,
	h4, .h4,
	h5, .h5 {
		margin-top: 15px;

		@include min-screen($screen-sm-min) {
			margin-top: 0;
		}
	}
	}
	
	@media screen and(max-width: 768px){
		.read-more__content {
			h2, .h2{
				margin-top: 30px;

			}
	}
}

.read-more__content p {
	&:last-of-type {
		margin-bottom: 60px;
	}
}

.read-more__content.read-more__content-full p {
	 &:last-of-type {
		 margin-bottom: 0;
	 }
 }

.read-more__content-minimized {
	max-height: 270px;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: max-height $duration-normal $delay-none $ease-standard;
}

.read-more__button-wrapper {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 2;
	text-align: center;
	background-image: linear-gradient(to bottom, $background-white, $background-white 100%);
	transition: background $duration-normal $delay-none $ease-in-out;

	.background-light-grey & {
		background-image: linear-gradient(to bottom, $background-light-grey, $background-light-grey 100%);
	}
	.background-dark-grey & {
		background-image: linear-gradient(to bottom, $background-dark-grey, $background-dark-grey 100%);
	}

	.read-more__content-minimized & {
		padding-top: 60px;

		@include min-screen($screen-sm-min) {
			padding-top: 40px;
		}

		background-image: linear-gradient(to bottom, rgba($background-white, 0), $background-white 50%);

		.background-light-grey & {
			background-image: linear-gradient(to bottom, rgba($background-light-grey, 0), $background-light-grey 50%);
		}
		.background-dark-grey & {
			background-image: linear-gradient(to bottom, rgba($background-dark-grey, 0), $background-dark-grey 50%);
		}
	}
}

.read-more__button-wrapper button {
	padding-left: 0;
	padding-right: 0;
	display: none;
	width: 100%;
	z-index: 3;

	@include min-screen($screen-sm-min) {
		width: 50%;
	}

	&:focus {
		outline: none;
		box-shadow: none;
		border: solid 1px #000;
	}

	&.display-inline-block {
		display: inline-block;
	}
}
