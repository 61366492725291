.popover {
	z-index: 800;
	border: none;
	border-radius: 0;
	padding: 0;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.12);

	button, .btn {
		padding: 7.5px;
	}
}

.popover.top > .arrow {
	border-top: none;
}

.popover.top {
	height: auto;
}

.popover-title {
	background-color: $background-light-grey;
	border-radius: 0;
	font-size: 13px;
	letter-spacing: 0.2px;
	text-transform: uppercase;
	text-align: center;
}
