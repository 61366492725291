.menu-wrapper {
	@include min-screen($screen-sm-min) {
		display: block !important;
	}
}

.menu-wrapper > .navigation {
	@include min-screen($screen-sm-min) {
		.navigation__item {
			a {

			}
		}
	}
}

.navigation {
	position: relative;
	list-style: none;
	padding: 0;
	margin: 0;
	text-align: center;

	.navigation__item {

		@include min-screen($screen-sm-min) {
			position: relative;
			padding: 0 10px;
		}

		&:after {
			@include min-screen($screen-sm-min) {
				position: absolute;
				right: -1px;
				top: 50%;
				transform: translateY(-50%);
				content: '|';
				color: black;
			}
		}
		&:last-child {
			&:after {
				content: none;
			}
		}
	}
}

.navigation__item {
	position: relative;
	display: block;
	line-height: 22px;
	font-weight: 300;

	@include min-screen($screen-sm-min) {
		display: inline-block;
	}

	&.nav-path-selected {
		a {
			color: $link-active-color;
		}
	}

	a {
		display: block;
		padding: 10px 20px;
		text-align: left;

		color: black;
		font-size: 14px;
		font-weight: lighter;

		@include min-screen($screen-sm-min) {
			font-size: 18px;
			padding: 14px 0;
			text-align: center;
		}

		&:hover,
		&:focus,
		&.js-is-active,
		&.is-active {
			text-decoration: none;
		}

		&:not(.js-is-active),
		&:not(.is-active) {
			&:hover {
				opacity: 0.75;
			}
		}

		&.js-is-active,
		&.is-active {
			color: $link-active-color;
		}
	}
}

#header {
	position: fixed;
	width: 100%;

	background-color: $background-white;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
	transition: box-shadow $duration-fast $delay-none $ease-in-out;

	@include min-screen($screen-sm-min) {
		position: relative;
	}

	.navigation {
		@include min-screen($screen-sm-min) {
			height: 60px;
		}
	}

	.scrolling-navigation {
		background-color: $background-sub-nav;
		border-top: 1px solid $border-dark-grey;
		//border-bottom: 1px solid $border-dark-grey;
		z-index: 925;
		box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
		transition: box-shadow $duration-fast $delay-none $ease-in-out;

		.navigation {
			height: 50px;
			margin: 0 auto;
		}

		.navigation__item {
			a {
				padding: 14px 35px;
				font-size: 13px;
			}
		}
	}

	.boxed-navigation-items {
		.navigation__item {
			border-left: 1px solid $border-dark-grey;

			&:last-of-type {
				border-right: 1px solid $border-dark-grey;
			}

			@include max-screen($screen-xs-max) {
				&:first-of-type {
					border-left: none;
				}
				&:last-of-type {
					border-right: none;
				}
			}

			&:after {
				content: '';
			}
		}
	}


	.scrolling-navigation + .scrolling-navigation {
		border-top: 1px solid #B3C6CA;
	}
}

.js-nav-is-loaded .header-wrapper {
	box-shadow: none;
}

.js-nav-is-loaded .sub-navigation-wrapper {
	box-shadow: none;
}

.scrolling-navigation {
	.navigation-wrapper {

		position: relative;
		margin: auto;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}
}

#section-navigation.scrolling-navigation {
	overflow: hidden;
	background-color: #C0CFD3;
	z-index: 910;
	top: 1px;
	transition: box-shadow $duration-fast $delay-none $ease-in-out;

	&::after {
		position: absolute;
		content: '';
		top: 0;
		right: 0;
		height: 100%;
		width: 100px;
		pointer-events: none;
		visibility: visible;
		opacity: 1;
		transition: opacity 300ms ease 0s,visibility 0s ease 0s;
		background: linear-gradient(to left, #81A0A7, transparent);
		@include min-screen($screen-sm-min) {
			display: none;
		}
	}

	&.js-is-ended {
		&::after {
			visibility: hidden;
			transition: opacity 300ms ease 0s,visibility 300ms ease 0s;
			opacity: 0;
		}
	}

	.navigation {
		margin: auto;
		height: auto;

		@include min-screen($screen-sm-min) {
			height: 50px;
		}
	}

	.navigation__item {
		float: none;
		border: none;

		.js-is-active {
			color: inherit;
			text-decoration: underline;
		}

		&:after {
			@include max-screen($screen-sm-max) {
				position: absolute;
				right: -1px;
				top: 50%;
				transform: translateY(-50%);
				content: '|';
				color: black;
			}
		}

		&:last-child {
			&:after {
				content: none;
			}
		}

		a {
			padding: 20px 10px;
			font-size: 13px;

			@include min-screen($screen-sm-min) {
				padding: 14px 0;
			}
		}
	}
}

#header .scrolling-navigation {
	position: relative;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

	@include max-screen($screen-xs-max) {
		.navigation {
			margin: 0;
			width: auto;
		}

		.navigation__item {
			float: left;
		}
	}
}
.undernav-padding{
	width: 100%;
	height: 60px;

	@include min-screen($screen-sm-min) {
		display: none;
	}
}

.ccm-toolbar-visible{
	#header{
		position: relative;
	}
		.undernav-padding{
			display: none;
		}

}
.navigation__item{
	.ccm-block-topic-list-topic-selected.active{
		color: #81A0A7;
	}
}


@media screen and(max-width:768px){
#header .scrolling-navigation  {
	.navigation__item:first-child{
		a {
			padding-left: 15px;
		}
	}
}
}
