/* Sections */
$section-padding-mobile: 35px;
$block-padding-bottom-mobile: 30px;
$section-padding-desktop: 65px;
$block-padding-bottom-desktop: 65px;

.content-section {
	padding: $block-padding-bottom-mobile 0;

	@include min-screen($screen-md-min) {
		padding: $block-padding-bottom-desktop 0;
	}

	&:first-of-type {
		padding-top: $section-padding-mobile;

		@include min-screen($screen-md-min) {
			padding-top: $section-padding-desktop;
		}
	}

	&.background-none {
		padding: 0;
	}

	&.background-white {
		+ .background-white{
			padding-top: 0;

			&:last-of-type {
				padding-bottom: $section-padding-mobile;

				@include min-screen($screen-md-min) {
					padding-bottom: $section-padding-desktop;
				}
			}
		}

		+ .background-dark-grey,
		+ .background-light-grey {
			padding-bottom: $section-padding-mobile;

			@include min-screen($screen-md-min) {
				padding-bottom: $section-padding-desktop;
			}
		}

		+ .background-dark-grey {
			border-top: 1px solid $border-dark-grey;
		}

		+ .background-light-grey {
			border-top: 1px solid $border-light-grey;
		}
	}


	&.background-light-grey {
		+ .background-light-grey {
			padding-top: 0;
		}

		+ .background-white,
		+ .background-dark-grey {
			padding-bottom: $section-padding-mobile;

			@include min-screen($screen-md-min) {
				padding-bottom: $section-padding-desktop;
			}
		}
		+ .background-dark-grey {
			border-top: 1px solid $border-dark-grey;
		}
	}

	&.background-dark-grey {
		+ .background-dark-grey {
			padding-top: 0;
		}

		+ .background-white,
		+ .background-light-grey {
			padding-bottom: $section-padding-mobile;

			@include min-screen($screen-md-min) {
				padding-bottom: $section-padding-desktop;
			}
		}

		+ .background-light-grey {
			border-top: 1px solid $border-light-grey;
		}
	}
}

/* Content */
.container {
	img {
		max-width: 100%;
		height: auto;
	}
}

.ccm-toolbar-visible {
	main {
		margin-top: 0px;
	}
}
.toggle-next-section.toggled + .content-section{
	padding-bottom: 15px ;
}

.content-section.toggle-next-section > .container.text-center > .btn.btn-primary.enable-next-section{
	margin-top: -45px;
}

@media (min-width: 768px){

.content-section.toggle-next-section > .container.text-center > .btn.btn-primary.enable-next-section{
	margin-top: -50px;
}
}

.background-{
	padding-top: 0;
}


section.toggle-next-section{
	padding-top: 0;
}

section.content-section.toggle-next-section.toggled{
	margin-top: -30px;
}
